import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    /*{
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },*/
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `${AUTH_PREFIX_PATH}/verify-reset-password`,
        component: React.lazy(() => import('views/auth-views/authentication/reset-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'sidenav.dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import('views/app-views/dashboard')),
    },
    {
        key: 'sidenav.partners',
        path: `${APP_PREFIX_PATH}/partners`,
        component: React.lazy(() => import('views/app-views/partners')),
    },
    {
        key: 'sidenav.partners.add',
        path: `${APP_PREFIX_PATH}/partners/add`,
        component: React.lazy(() => import('views/app-views/partners/Add')),
    },
    {
        key: 'sidenav.locations',
        path: `${APP_PREFIX_PATH}/locations`,
        component: React.lazy(() => import('views/app-views/partners')),
    },
    {
        key: 'sidenav.locations.add',
        path: `${APP_PREFIX_PATH}/locations/add`,
        component: React.lazy(() => import('views/app-views/partners/Add')),
    },
    {
        key: 'sidenav.clients',
        path: `${APP_PREFIX_PATH}/clients`,
        component: React.lazy(() => import('views/app-views/clients')),
    },
    {
        key: 'sidenav.clients',
        path: `${APP_PREFIX_PATH}/clients/add`,
        component: React.lazy(() => import('views/app-views/clients/Add')),
    },
    {
        key: 'sidenav.vendors',
        path: `${APP_PREFIX_PATH}/vendors`,
        component: React.lazy(() => import('views/app-views/vendors')),
    },
    {
        key: 'sidenav.screens',
        path: `${APP_PREFIX_PATH}/screens`,
        component: React.lazy(() => import('views/app-views/screens')),
    },
    {
        key: 'sidenav.screens',
        path: `${APP_PREFIX_PATH}/screens/add`,
        component: React.lazy(() => import('views/app-views/screens/Add')),
    },
    {
        key: 'sidenav.bookings',
        path: `${APP_PREFIX_PATH}/bookings`,
        component: React.lazy(() => import('views/app-views/bookings')),
    },
    {
        key: 'sidenav.bookings',
        path: `${APP_PREFIX_PATH}/bookings/add`,
        component: React.lazy(() => import('views/app-views/bookings/Add')),
    },
    {
        key: 'sidenav.campaigns',
        path: `${APP_PREFIX_PATH}/campaigns`,
        component: React.lazy(() => import('views/app-views/campaignsv2')),
    },
    {
        key: 'sidenav.campaigns',
        path: `${APP_PREFIX_PATH}/campaigns/add`,
        component: React.lazy(() => import('views/app-views/campaignsv2/Add')),
    },
    {
        key: 'sidenav.campaigns',
        path: `${APP_PREFIX_PATH}/campaigns/:id`,
        component: React.lazy(() => import('views/app-views/campaigns/Details')),
    }
]
